import React, { forwardRef }  from "react"
import PropTypes from "prop-types"
import Transaction from "./Transaction"
import ReactToPrint from 'react-to-print';
import PrintComponents from "react-print-components";

function Printing(props) {
  const date = new Date();
  const todaydate = date.toDateString();
  console.log("date today", todaydate);
  if (props.state.customerId != "") {
    return (
      <React.Fragment>
        <div className="col-lg-12" style={{ padding: '15px', width: '100%' }} >
          <div>
            <div style={{width: "100%"}}>
              <h4 style={{fontSize: "16px", textTransform: "uppercase", textAlign: "center", fontWeight: "bold"}}>AuroraEstore</h4>
              <p style={{fontSize: "13px", textAlign: "center", marginBottom: "0"}}>Kalanki, NBTC Building <br/> Shop No.: 3039 (0-Level)</p>
              <p style={{fontSize: "11px", textAlign: "center"}}>PAN NO.:  690339585</p>
            </div>
          </div>

            <div className="col-lg-12" style={{ backgroundColor: '#e1e1e1', padding: '10px', textAlign: 'center' }} >
              <h4 style={{fontSize: "16px", textTransform: "uppercase", textAlign: "center"}}>INVOICE</h4>
              {/* {props.state.date} */}
            </div>


          <div className="clearfix"></div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h4 style={{fontSize: "12px", textAlign: "left"}}><b>Bill To:</b> <span>{props.state.customerList.find(id => id.id == props.state.customerId).name}</span></h4>
            <h4 style={{fontSize: "12px", textAlign: "left"}}><b>Date:</b> {todaydate}</h4>
          </div>


          <table className="table table-striped table-bordered" style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                <th style={{ width: '60%' }}>Particulars</th>
                <th style={{ textAlign: 'center', width: '12%' }} >Quanitity</th>
                <th style={{ textAlign: 'center', width: '13%' }} >Rate</th>
                <th style={{ width: '15%' }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {props.state.selectedProducts.map(sel =>
                <tr>
                  <td>{sel.name}</td>
                  <td style={{textAlign: "center"}}>{sel.primary_quantity}</td>
                  <td>{sel.primary_rate}</td>
                  <td>{sel.total}</td>
                </tr>
              )}

            </tbody>
          </table>

          <div style={{ display: 'flex' }} >
            {/* <div style={{ width: '60%' }}>
              <h4 style={{ fontWeight: 'bold', fontSize: '12px' }}>Description:</h4>
              <p style={{ fontSize: '12px', textAlign: 'justify', padding: '0 20px' }}>{props.state.doc_description}</p>
            </div> */}
            {/* <div style={{ width: '10%' }}></div> */}
            <div>
              <h4 style={{fontSize: '12px'}} ><b>Total Amount:</b> <span>{props.state.finalTotal}</span></h4>
              
              {props?.state?.discountNumber?.length>0&&
              <h4 style={{fontSize: '12px'}} ><b>Discount Amount:</b> <span>{props.state.discountNumber}</span></h4>}
               {props?.state?.vatChecked&&
                  <>
                    <h4 style={{fontSize: '12px'}} ><b>Taxable Amount:</b> <span>{props.state.taxableAmount}</span></h4>
                    <h4 style={{fontSize: '12px'}} ><b>Vat Perecentage:</b> <span>{props.state.vatNumber}</span></h4>
                  </>
                }
              <h4 style={{fontSize: '12px'}}><b>NET Amount:</b> <span>{props.state.netAmount}</span></h4>
            </div>
          </div>
          <div className="col-lg-12">
            <p style={{fontSize: "12px", textAlign: "center", marginTop: "10px"}} >T&C: No Refund, No Exchange </p>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <div></div>

    )
  }

}

export default Printing

//to handle afterprint event
export const ComponentToPrint = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="component-to-print">
      <Printing state={props.state} />
    </div>
  );
});