import React from "react";
import AsyncSelect from "react-select/async";
import Axios from "axios";
import { useState } from "react";
import { domain } from "../Url";
import { useEffect } from "react";

export const ProductField = (props) => {
  const [productInfo, setProductInfo] = React.useState({
    id: [],
    discount: "",
    priority: "",
  });

  const [selectedValue, setSelectedValue] = useState([]);
  const [discountValue, setDiscountValue] = React.useState();

  React.useEffect(() => {
    if (
      productInfo.id.length > 0 &&
      productInfo.discount.length > 0 &&
      productInfo.priority.length > 0
    ) {
      props.pingFieldValue(props.index, productInfo);
    }
  }, [productInfo]);

  useEffect(() => {
    setSelectedValue([]);
  }, [props.offerType, props.isSubmited]);

  const loadOptions = (value) => {
    const getApi =
      props.offerType === "product" ? "search_products" : "search/brands";
    return Axios.get(`${domain}/api/${getApi}/${value}`).then((res) => {
      return res.data.map((option) => ({
        label: option.name,
        value: option.id,
      }));
    });
  };

  const handleChange = (inputValue) => {
    setSelectedValue(inputValue);
    setProductInfo((p) => {
      return {
        ...p,
        id: inputValue.map((item) => item.value),
      };
    });
  };

  return (
    <div className="product-field">
      <div className="product-field-block">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {props?.index === 0 && (
                <p className="content">{props.offerType} Name</p>
              )}
              <div className="multi-select-field">
                <AsyncSelect
                  isMulti
                  value={selectedValue}
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              {props?.index === 0 && <p className="content">Discount</p>}
              <input
                type="number"
                className="form-control"
                min={1}
                onWheel={(e) => e.target.blur()}
                value={Math.round(discountValue * 100) / 100}
                onChange={(e) => {
                  setDiscountValue(e.target.value);
                  setProductInfo({ ...productInfo, discount: e.target.value });
                }}
              />
            </div>
            <div className="col-md-2">
              {props?.index === 0 && <p className="content">Priority</p>}
              <input
                type="number"
                min={1}
                onWheel={(e) => e.target.blur()}
                className="form-control"
                onChange={(e) => {
                  setProductInfo({ ...productInfo, priority: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
