import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
// import ReactHtmlParser from 'react-html-parser';
// import { runInThisContext } from "vm";
// import Select from "react-select";
import PrintComponents from "react-print-components";
import ReactOnRails from "react-on-rails";
import Printing from "./Printing";
import BarcodeReader from "react-barcode-reader";

class TransactionReturn extends React.Component {
  document_description = "";
  description = "";
  constructor(props) {
    super(props);

    this.state = {
      showCashCredit: false,
      error: {
        cash: false,
      },
      selectedValue: "",
      pur_description: "",
      isAdmin: props.isAdmin,
      disablePatron: false,
      type: props.type[0].vendor_type,
      customerType: "",
      cash: "",
      credit: "",
      primary_rate: 0,
      secondary_rate: 0,
      error: "",
      customerList: [],
      batchList: [],
      productList: [],
      customerId: "",
      singleProductDetail: null,
      primary_name: "",
      totalstock: "",
      selectedProductName: "",
      primary_quantity: "",
      secondary_quantity: "",
      isBarcodeScan: false,
      total: 0,
      finalTotal: 0,
      primary_total: 0,
      secondary_total: 0,
      selectedProducts: [],
      selectedProductId: null,
      discountChecked: false,
      vatChecked: false,
      vatNumber: 13,
      canEditPrice: false,
      taxableAmount: 0,
      batch: "",
      selectedBatch: "",
      canEditDescription: true,
      date: new Date().toLocaleString(),
      result: "No result",
      productPriceBarCodeToken: "",
      payType: ""
    };
    this.customerName = "";
    this.onSelect = this.onSelect.bind(this);
    this.customerSelect = this.customerSelect.bind(this);
    this.batchSelect = this.batchSelect.bind(this);
    this.formData = this.formData.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.selectedProductsView = this.selectedProductsView.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.implementVat = this.implementVat.bind(this);
    this.calculateTaxableAmount = this.calculateTaxableAmount.bind(this);
    this.setWholeSalePrice = this.setWholeSalePrice.bind(this);
    this.setRetailPrice = this.setRetailPrice.bind(this);
    this.hiddenDescription = this.hiddenDescription.bind(this);
    this.cashPaid = false;
    this.submitForm = this.submitForm.bind(this);
    this.primary_rate = 0;
    this.secondary_rate = 0;
    this.handleScan = this.handleScan.bind(this);
  }

  getRequestParams(name) {
    let params = new URLSearchParams(window.location.search);
    return params.get(name);
  }

  implementVat() { }

  async getAxiosRequest(url, params) {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        return {
          status: 11,
          response: response.data,
        };
      })
      .catch(function (error) {
        return {
          status: 22,
          response: error,
        };
      });
  }

  submitForm() {
    if (this.state.error.cash) {
      toastr.error("Cash invalid!!");
    } else {
      axios
        .post("/save_return_transaction", {
          paper_type: this.state.paperType,
          authenticity_token: ReactOnRails.authenticityToken(),
          productList: this.state.selectedProducts,
          type: this.getRequestParams("type"),
          totalAmount: this.state.finalTotal,
          taxableAmount: this.state.taxableAmount,
          customerId: this.state.customerId,
          primary_rate: this.state.primary_rate,
          secondary_rate: this.state.secondary_rate,
          doc_description: this.state.doc_description,
          pur_description: this.state.pur_description,
          netAmount: this.state.netAmount,
          cashAmount: this.state.cash,
          creditAmount: this.state.credit,
          payType: this.state.payType
        })
        .then(function (response) {
          window.location.reload();
          toastr.success("Successfully saved!");
        })
        .catch(function (error) {
          toastr.error("Error Saving!");
          console.log(error);
        });
    }
  }

  componentWillMount() {
    axios
      .get("/customer_list", {
        params: { type: this.getRequestParams("type") },
      })
      .then((response) => {
        this.setState({
          customerList: response.data,
        });
      })
      .catch(function (error) { });
    axios
      .get("/product_list")
      .then((response) => {
        this.setState({
          productList: response.data,
        });
      })
      .catch(function (error) { });
  }

  componentDidMount() {
    var self = this;
    $("input[type=number]").on("wheel", function (e) {
      return false;
    });
    $(document).ready(function () {
      $("select")
        .select2()
        .on("change", function (e) {
          if (e.target.id == 1) {
            self.customerSelect(e);
          } else if (e.target.id == 2) {
            self.onSelect(e);
          } else {
            self.batchSelect(e);
          }
        });
    });
  }

  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    switch (name) {
      case "discount":
        var discountCheck = this.state.discountChecked;
        var newDiscountCheck = !discountCheck;
        this.setState(
          {
            discountChecked: newDiscountCheck,
          },
          () => {
            this.calculateFinalTotal();
          }
        );
        break;
      case "discountNumber":
        this.setState(
          {
            [name]: value,
          },
          () => {
            this.calculateFinalTotal();
          }
        );
        break;
      case "vat":
        var vatChecked = this.state.vatChecked;
        var newVatChecked = !vatChecked;
        this.setState(
          {
            vatChecked: newVatChecked,
          },
          () => {
            this.calculateFinalTotal();
          }
        );
        break;
      default:
        this.setState(
          {
            [name]: value,
          },
          () => {
            this.calculateTotal();
          }
        );
    }
  }
  hiddenDescription(document) {
    this.description += document;
    this.setState({
      pur_description: this.description,
    });
  }
  updateDocument = (document) => {
    this.document_description = document;
    this.setState({
      doc_description: this.document_description,
      singleProductDetail: null,
    });
  };

  onSelect = (obj) => {
    var value = JSON.parse(obj.target.selectedOptions[0].dataset.obj);
    this.setSelectedValue(value);
  };

  setSelectedValue = async (value) => {
    var selectedName = value.name;
    var selectedValue = value.value;
    this.setState({
      batchList: [],
      selectedProductId: selectedValue,
      selectedProductName: selectedName,

      totalstock: "",
    });
    this.setState((prevState) => ({
      selectedProduct: value,
    }));

    const batch = await axios.get("/get_batch", {
      params: {
        productId: selectedValue,
        transactionType: this.getRequestParams("type"),
      },
    });
    var tempBatch = batch.data;
    var selBatch;

    if (this.state.isBarcodeScan) {
      var batchSelect = tempBatch.filter(
        (item) => item.id == this.state.productPriceBarCodeToken
      );
      selBatch = batchSelect[0].batch;
    } else {
      selBatch = batch.data[batch.data.length - 1].batch;
    }
    this.setState(
      {
        batchList: batch.data,
        batch: batch.data[0].batch,
        selectedBatch: selBatch,
      },
      () => {
        $(`#3`).val(selBatch).trigger("change");
      }
    );
    // try {
    //   const productDetail = await axios.get("/product_detail", {
    //     params: {
    //       productId: selectedValue,
    //       transactionType: this.getRequestParams("type"),
    //       customerId: this.state.customerId,
    //       batch: selBatch
    //     },
    //   });
    //   this.setState({
    //     singleProductDetail: productDetail.data,
    //     selectedProductName: selectedName,
    //   });
    // } catch (e) {
    //   console.log(e);
    // }
  };

  handleScan = (data) => {
    if (this.state.customerId != "") {
      // var categoryToken = data.substring(0, 3);
      var productToken = data.substring(3, 8);

      var productPriceToken = data.substring(8, 13);
      this.setState({
        productPriceBarCodeToken: productPriceToken,
      });
      var retailPrice = data.substring(14, data.length);
      var productList = this.state.productList;

      var selectedList = productList.filter((item) => item.id == productToken);
      if (selectedList.length == 0) {
        toastr.error("No Product registered!!!");
      } else {
        this.setState(
          {
            primary_quantity: 1,
            isBarcodeScan: true,
          },
          () => {
            const value = selectedList[0].value;

            $(`#2`).val(value).trigger("change");
          }
        );
      }
    } else {
      toastr.error("Please select the patron");
    }
  };

  calculateFinalTotal() {
    var total = 0;
    this.state.selectedProducts.map(
      (singleProduct) => (total += singleProduct.total)
    );
    this.setState(
      {
        finalTotal: total,
      },
      () => {
        this.calculateTaxableAmount();
      }
    );
  }

  removeProduct(index) {
    var arrayInState = [...this.state.selectedProducts];
    arrayInState.splice(index, 1);
    this.setState(
      {
        selectedProducts: arrayInState,
      },
      () => {
        this.updateDescriptionafterselect(this.state.selectedProducts);
        this.calculateFinalTotal();
      }
    );
  }

  customerSelect(e) {
    var type = e.target.options[e.target.selectedIndex].getAttribute("type");
    var token = e.target.options[e.target.selectedIndex].getAttribute(
      "vendor_token"
    );
    this.customerName = e.target.selectedOptions[0].label;

    // if (this.getRequestParams("type") == "Sales_Return") {
    //   fetch("get_vendor_product?vendor_token=" + token)
    //     .then((res) => res.json())
    //     .then((json) => {
    //       this.setState({
    //         productList: json.data,
    //       });
    //     });
    //   this.setState({
    //     customerId: e.target.value,
    //   });
    // } else {
    this.setState({
      customerType: type,
      customerId: e.target.value,
    });
    // }
  }

  batchSelect(e) {
    if (e.target.value != "Select") {
      var batch = e.target.value;
      axios
        .get("/get_product_stock", {
          params: {
            productId: this.state.selectedProductId,
            batch: batch,
          },
        })
        .then((response) => {
          this.setState({
            totalstock: response.data.data[0].totalstock,
          });
        })
        .catch(function (error) { });
      axios
        .get("/product_detail", {
          params: {
            productId: this.state.selectedProductId,
            transactionType: this.getRequestParams("type"),
            customerId: this.state.customerId,
            batch: batch,
          },
        })
        .then((response) => {
          this.setState(
            {
              singleProductDetail: response.data,
              batch: batch,
              selectedBatch: batch,
            },
            () => {
              this.calculateTotal();
              if (this.state.type != "Internal Wholesaler")
                this.setState({
                  primary_rate: this.state.singleProductDetail[0].cost_price_primary,
                  secondary_rate: this.state.singleProductDetail[0].cost_price_secondary
                })

            }
          );
        })
        .catch(function (error) { });
    }
  }
  setWholeSalePrice() {
    this.setState(
      {
        primary_rate: this.state.singleProductDetail[0].whole_price_primary,
        secondary_rate: this.state.singleProductDetail[0].whole_price_secondary,
      },
      () => {
        this.calculateTotal();
      }
    );

    // this.primary_rate = this.state.singleProductDetail[0].whole_price_primary
    // this.secondary_rate = this.state.singleProductDetail[0].whole_price_secondary
  }

  setRetailPrice() {
    this.setState(
      {
        primary_rate: this.state.singleProductDetail[0].retail_price_primary,
        secondary_rate: this.state.singleProductDetail[0]
          .retail_price_secondary,
      },
      () => {
        this.calculateTotal();
      }
    );
  }

  selectedProductsView() {
    if (this.state.selectedProducts.length > 0) {
      return (
        <div>
          <table className="table table-striped table-bordered" id="table1">
            <thead>
              <tr>
                <th rowSpan={2}>Particulars</th>
                <th colSpan={2}>Quantity</th>
                <th colSpan={2}>Rate</th>
                <th rowSpan={2}>Total</th>
                <th rowSpan={2}>Action</th>
              </tr>
              <tr>
                <th>Primary</th>
                <th>Secondary</th>
                <th>Primary</th>
                <th>Secondary</th>
              </tr>
            </thead>
            <tbody>
              {this.state.selectedProducts.map((singleProduct, idx) => (
                <tr key={idx}>
                  <td>{singleProduct.name}</td>
                  <td>{singleProduct.primary_quantity}</td>
                  <td>{singleProduct.secondary_quantity}</td>
                  <td>{singleProduct.primary_rate}</td>
                  <td>{singleProduct.secondary_rate}</td>
                  <td>{singleProduct.total}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      obj={JSON.stringify(singleProduct)}
                      onClick={() => this.removeProduct(idx)}
                      style={{ borderRadius: "50%", padding: "2px 4px" }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }

  calculateTotal() {
    try {
      if (this.getRequestParams("type") == "Sales_Return") {
        var primary = this.state.primary_rate * this.state.primary_quantity;
      } else {
        if (
          this.state.customerType == "Retailer" &&
          this.state.type != "Internal Wholesaler"
        ) {
          var primary =
            this.state.singleProductDetail[0].retail_price_primary *
            this.state.primary_quantity;
        } else if (
          (this.state.customerType == "Wholesaler" ||
            this.state.customerType == "Internal Wholesaler") &&
          this.state.type != "Internal Wholesaler"
        ) {
          var primary =
            this.state.singleProductDetail[0].whole_price_primary *
            this.state.primary_quantity;
        } else {
          var primary = this.state.primary_rate * this.state.primary_quantity;
        }
      }
    } catch (e) {
      var primary = 0;
    }

    try {
      if (this.getRequestParams("type") == "Sales_Return") {
        var secondary =
          this.state.secondary_rate * this.state.secondary_quantity;
      } else {
        if (this.state.customerType == "Retailer") {
          var secondary =
            this.state.singleProductDetail[0].retail_price_secondary *
            this.state.secondary_quantity;
        } else if (this.state.customerType == "Wholesaler") {
          var secondary =
            this.state.singleProductDetail[0].whole_price_secondary *
            this.state.secondary_quantity;
        } else if (this.state.customerType == "Internal Wholesaler") {
          var secondary =
            this.state.singleProductDetail[0].whole_price_secondary *
            this.state.secondary_quantity;
        } else {
          var secondary =
            this.state.secondary_rate * this.state.secondary_quantity;
        }
      }
    } catch (e) {
      var secondary = 0;
    }

    if (Number.isNaN(primary)) {
      primary = 0;
    }

    if (Number.isNaN(secondary)) {
      secondary = 0;
    }

    var total = primary + secondary;
    this.setState(
      {
        primary_total: primary,
        secondary_total: secondary,

        total: total,
      },
      () => {
        if (this.state.isBarcodeScan) {
          this.checkValidationForProductionAddition();
        }
      }
    );
  }

  handleDiscountChecked() { }

  checkValidationForProductionAddition = () => {
    if (this.getRequestParams("type") != "Sales_Return") {
      if (
        this.state.primary_quantity == "" &&
        this.state.secondary_quantity == ""
      ) {
        toastr.error("Please Set the quanitty");
      } else if (this.state.selectedBatch == "") {
        toastr.error("Please Set the batch");
      }
      // else if (
      //   parseInt(this.state.totalstock.split("&")[0], 10) <
      //   parseInt(this.state.primary_quantity, 10)
      // ) {
      //   toastr.error("Qantity greater than stock");
      // }
      else if (
        parseInt(this.state.totalstock.split("&")[1], 10) <
        parseInt(this.state.secondary_quantity, 10)
      ) {
        toastr.error("Qantity greater than stock");
      } else {
        // if (this.state.isBarcodeScan) {
        //   this.calculateTotal();
        // }
        this.addProduct();
      }
    } else {
      if (
        this.state.primary_quantity == "" &&
        this.state.secondary_quantity == ""
      ) {
        toastr.error("Please Set the quanitty");
      } else if (
        this.state.secondary_rate == "" &&
        this.state.primary_rate == ""
      ) {
        toastr.error("Please set the price");
      } else if (this.state.selectedBatch == "") {
        toastr.error("Please Set the batch");
      } else {
        this.addProduct();
      }
    }

    this.setState({
      disablePatron: true,
      showCashCredit: true,
    });
  };

  addProduct() {
    var type = this.getRequestParams("type");
    if (type == "Sales_Return") {
      var noun = " from ";
    } else {
      var noun = " to ";
    }
    var index = -1;
    if (this.getRequestParams("type") == "Sales_Return") {
      var selectedProduct = {
        product_id: this.state.selectedProductId,
        name: this.state.selectedProductName,
        primary_quantity: this.state.primary_quantity,
        secondary_quantity: this.state.secondary_quantity,
        product_price_token_id: this.state.singleProductDetail[0]
          .product_price_token_id,
        primary_unit: this.state.singleProductDetail[0].primary_unit,
        secondary_unit: this.state.singleProductDetail[0].secondary_unit,
        primary_rate: this.state.primary_rate,
        secondary_rate: this.state.secondary_rate,
        cartoon_size: this.state.singleProductDetail[0].cartoon_size,
        batch: this.state.batch,
        total: this.state.total,
      };
    } else if (this.state.customerType == "Retailer") {
      index = this.state.selectedProducts.findIndex(
        (item) => item.product_id == this.state.selectedProductId
      );
      if (index != -1) {
        if (this.state.isBarcodeScan) {
          var quantity =
            parseFloat(this.state.selectedProducts[index].primary_quantity) + 1;
        } else {
          var quantity =
            parseFloat(this.state.selectedProducts[index].primary_quantity) +
            parseFloat(this.state.primary_quantity);
        }
        var selectedProductFromState = [...this.state.selectedProducts];
        selectedProductFromState[index]["primary_quantity"] = quantity;
        selectedProductFromState[index]["total"] =
          quantity * this.state.singleProductDetail[0].retail_price_primary;
        var editedSelectedProducts = selectedProductFromState;
      } else {
        var selectedProduct = {
          product_id: this.state.selectedProductId,
          name: this.state.selectedProductName,
          primary_quantity: this.state.primary_quantity,
          secondary_quantity: this.state.secondary_quantity,
          product_price_token_id: this.state.singleProductDetail[0]
            .product_price_token_id,
          primary_unit: this.state.singleProductDetail[0].primary_unit,
          secondary_unit: this.state.singleProductDetail[0].secondary_unit,
          primary_rate: this.state.singleProductDetail[0].retail_price_primary,
          secondary_rate: this.state.singleProductDetail[0]
            .retail_price_secondary,
          cartoon_size: this.state.singleProductDetail[0].cartoon_size,
          batch: this.state.batch,
          total: this.state.total,
        };
      }
    } else if (
      this.state.customerType == "Wholesaler" ||
      this.state.customerType == "Internal Wholesaler"
    ) {
      index = this.state.selectedProducts.findIndex(
        (item) => item.product_id == this.state.selectedProductId
      );
      if (index != -1) {
        if (this.state.isBarcodeScan) {
          var quantity =
            parseFloat(this.state.selectedProducts[index].primary_quantity) + 1;
        } else {
          var quantity =
            parseFloat(this.state.selectedProducts[index].primary_quantity) +
            parseFloat(this.state.primary_quantity);
        }

        // this.removeProduct(index);
        var selectedProductFromState = [...this.state.selectedProducts];
        selectedProductFromState[index]["primary_quantity"] = quantity;
        (selectedProductFromState[index][
          "primary_rate"
        ] = this.state.singleProductDetail[0].whole_price_primary),
          (selectedProductFromState[index]["total"] =
            quantity * this.state.singleProductDetail[0].whole_price_primary);
        var editedSelectedProducts = selectedProductFromState;
      } else {
        var selectedProduct = {
          product_id: this.state.selectedProductId,
          name: this.state.selectedProductName,
          primary_quantity: this.state.primary_quantity,
          secondary_quantity: this.state.secondary_quantity,
          product_price_token_id: this.state.singleProductDetail[0]
            .product_price_token_id,
          primary_rate: this.state.singleProductDetail[0].whole_price_primary,
          secondary_rate: this.state.singleProductDetail[0]
            .whole_price_secondary,
          primary_unit: this.state.singleProductDetail[0].primary_unit,
          secondary_unit: this.state.singleProductDetail[0].secondary_unit,
          cartoon_size: this.state.singleProductDetail[0].cartoon_size,
          batch: this.state.batch,
          total: this.state.total,
        };
      }
    } else {
      var selectedProduct = {
        product_id: this.state.selectedProductId,
        name: this.state.selectedProductName,
        primary_quantity: this.state.primary_quantity,
        secondary_quantity: this.state.secondary_quantity,
        product_price_token_id: this.state.singleProductDetail[0]
          .product_price_token_id,
        primary_rate: this.state.primary_rate,
        secondary_rate: this.state.secondary_rate,
        primary_unit: this.state.singleProductDetail[0].primary_unit,
        secondary_unit: this.state.singleProductDetail[0].secondary_unit,
        cartoon_size: this.state.singleProductDetail[0].cartoon_size,
        batch: this.state.batch,
        total: this.state.total,
      };
    }
    if (index != -1) {
      var selectedProductFromState = editedSelectedProducts;
    } else {
      var selectedProductFromState = this.state.selectedProducts;
      selectedProductFromState.push(selectedProduct);
    }
    var total = 0;
    this.updateDescriptionafterselect(selectedProductFromState);
    if (
      this.state.secondary_quantity == undefined ||
      this.state.secondary_quantity == ""
    ) {
      selectedProductFromState.map((singleProduct) => {
        total += singleProduct.total;
      });
    } else if (
      this.state.primary_quantity == undefined ||
      this.state.primary_quantity == ""
    ) {
      selectedProductFromState.map((singleProduct) => {
        total += singleProduct.total;
      });
    } else {
      selectedProductFromState.map((singleProduct) => {
        total += singleProduct.total;
      });
    }
    this.setState(
      {
        selectedProducts: selectedProductFromState,
        singleProductDetail: null,
        name: "",
        primary_quantity: "",
        secondary_quantity: "",
        primary_rate: "",
        secondary_rate: "",
        total: 0,
        selectedProductId: null,
        finalTotal: total,
        isBarcodeScan: false,
      },
      () => {
        this.calculateFinalTotal();
      }
    );
  }
  updateDescriptionafterselect(selectedProductFromState) {
    var type = this.getRequestParams("type");

    if (type == "Sales_Return") {
      var noun = " from ";
    } else {
      var noun = " to ";
    }
    var description = "";
    if (
      this.state.secondary_quantity == undefined ||
      this.state.secondary_quantity == ""
    ) {
      selectedProductFromState.map((singleProduct) => {
        description +=
          this.getRequestParams("type") +
          " of " +
          singleProduct.primary_quantity +
          " " +
          singleProduct.primary_unit +
          " " +
          singleProduct.name +
          noun +
          this.customerName +
          ";";
      });
    } else if (
      this.state.primary_quantity == undefined ||
      this.state.primary_quantity == ""
    ) {
      selectedProductFromState.map((singleProduct) => {
        description +=
          this.getRequestParams("type") +
          " of " +
          singleProduct.secondary_quantity +
          " " +
          singleProduct.secondary_unit +
          " " +
          singleProduct.name +
          noun +
          this.customerName +
          ";";
      });
    } else {
      selectedProductFromState.map((singleProduct) => {
        description +=
          this.getRequestParams("type") +
          " of " +
          singleProduct.primary_quantity +
          " " +
          singleProduct.primary_unit +
          " " +
          " and " +
          singleProduct.secondary_quantity +
          " " +
          singleProduct.secondary_unit +
          " " +
          singleProduct.name +
          noun +
          this.customerName +
          ";";
      });
    }
    this.updateDocument(description);
  }
  createPriceInputFieldPrimary = () => {
    return (
      <input
        className="form-control"
        name="primary_rate"
        type="text"
        pattern="[0-9]*"
        onChange={this.handleChange.bind(this)}
        value={this.state.primary_rate}
        style={{ fontWeight: "bold" }}
      />
    );
  };

  createPriceInputFieldSecondary = () => {
    return (
      <input
        className="form-control"
        name="secondary_rate"
        type="text"
        pattern="[0-9]*"
        onChange={this.handleChange.bind(this)}
        readonly
        value={this.state.secondary_rate}
        style={{ fontWeight: "bold" }}
      />
    );
  };


  formData() {
    if (this.state.singleProductDetail != null) {
      return (
        <div>
          {this.state.type == "Internal Wholesaler" ? (
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "spacebetween",
              }}
            >
              <button
                className="btn btn-info"
                onClick={this.setRetailPrice}
                style={{ cursor: "pointer" }}
              >
                Retail Price
              </button>
              <button
                className="btn btn-info"
                onClick={this.setWholeSalePrice}
                style={{ cursor: "pointer" }}
              >
                Whole Sale Price
              </button>
            </div>
          ) : (
            ""
          )}

          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    className="form-control"
                    name="primary_quantity"
                    type="text"
                    pattern="[0-9]*"
                    onChange={this.handleChange.bind(this)}
                    placeholder={this.state.singleProductDetail[0].primary_unit}
                    value={this.state.primary_quantity}
                  />
                  {this.state.error}
                </td>
                <td>{this.createPriceInputFieldPrimary()}</td>
                <td>
                  <input
                    className="form-control"
                    name="primary_total"
                    readonly
                    value={this.state.primary_total}
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    className="form-control"
                    name="secondary_quantity"
                    type="text"
                    pattern="[0-9]*"
                    onChange={this.handleChange.bind(this)}
                    placeholder={
                      this.state.singleProductDetail[0].secondary_unit
                    }
                  />
                </td>
                <td>{this.createPriceInputFieldSecondary()}</td>
                <td>
                  <input
                    className="form-control"
                    name="secondary_total"
                    readonly
                    value={this.state.secondary_total}
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <label>Total Sum</label>
                </td>
                <td>
                  <input
                    className="form-control"
                    name="total"
                    type="text"
                    pattern="[0-9]*"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.total}
                    readonly
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }

  calculateTaxableAmount() {
    if (this.state.discountChecked) {
      if (typeof this.state.discountNumber == "undefined") {
        var taxableAmount = this.state.finalTotal;
      } else {
        var taxableAmount =
          this.state.finalTotal -
          (this.state.discountNumber * this.state.finalTotal) / 100;
      }
    } else {
      if (typeof this.state.discountNumber == "undefined") {
        var taxableAmount = this.state.finalTotal;
      } else {
        var taxableAmount = this.state.finalTotal - this.state.discountNumber;
      }
    }
    this.setState(
      {
        taxableAmount: taxableAmount,
      },
      () => {
        this.calculateNetAmount();
      }
    );
  }

  calculateNetAmount() {
    if (this.state.vatChecked) {
      var netAmount =
        this.state.taxableAmount +
        (this.state.vatNumber * this.state.taxableAmount) / 100;
    } else {
      var netAmount = this.state.taxableAmount;
    }
    this.setState({
      netAmount: netAmount,
      cash: netAmount,
      credit: 0,
    });
  }

  updateDescription = (e) => {
    this.document_description = e.target.value;
    this.setState({
      canEditDescription: true,
    });
  };

  checkIfCashOrCredit = () => {
    if (this.getRequestParams("type") != "Sales_Return") {
      return (
        <div>
          <label>Cash</label>
          <input
            type="number"
            onChange={this.changeNetAmount}
            defaultValue={this.state.cash}
            value={this.state.cash}
            name="cash"
            min="0"
            className="form-control"
          />
          {this.state.error.cash ? (
            <p style={{ color: "red" }}>
              Input value is greater than Net Amount
            </p>
          ) : (
            ""
          )}
          {/* <label>Credit</label>
          <input
            type="number"
            onChange={this.changeNetAmount}
            defaultValue={this.state.credit}
            value={this.state.credit}
            min="0"
            name="credit"
            className="form-control"
          /> */}
        </div>
      );
    }
  };

  changeNetAmount = () => {
    var net = this.state.netAmount;
    var name = event.target.getAttribute("name");
    var value = event.target.value;

    if (value > net) {
      this.setState({
        error: {
          cash: true,
        },
      });
    } else {
      this.setState({
        error: {
          cash: false,
        },
      });
    }

    if (value >= 0) {
      this.cashPaid = true;
    }
    if (name == "cash") {
      var val = net - value;
      this.setState({
        credit: val,
        cash: value,
      });
    } else {
      var val = net - value;
      this.setState({
        cash: val,
        credit: value,
      });
    }
  };

  userType() {
    this.setState({
      customerType: e.target.getAttribute("type"),
    });
  }

  onValueChange = (event) => {
    this.setState({
      payType: event.target.value
    });
  }

  render() {
    return (
      <React.Fragment>
        <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
        <div className="form-group ">
          <div className="row text-right container-fluid">
            <div className="col-sm-12 col-md-12">
              <div className="col-md-8"></div>
              {/* <div className=" col-md-3">
                <div className="input-group">
                  <span className="input-group-addon">Paper Types</span>
                  <input
                    className="form-control"
                    name="paperType"
                    type="text"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div> */}

              <div class="col-md-4">
                <PrintComponents
                  trigger={
                    <div>
                      <button className="btn btn-warning">
                        <i className="fa fa-print"></i> Print
                      </button>{" "}
                      <div className="clearfix"></div>
                    </div>
                  }
                >
                  <h1>
                    <Printing state={this.state} />
                  </h1>
                </PrintComponents>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="input-group m-b">
              <span className="input-group-addon">Patron</span>
              <select
                className="form-control"
                id="1"
                disabled={this.state.disablePatron}
              >
                <option selected disabled>
                  Select
                </option>
                {this.state.customerList.map((singleValue) => (
                  <option
                    key={singleValue.id}
                    vendor_token={singleValue.vendor_token}
                    type={singleValue.vendor_type}
                    value={singleValue.id}
                  >
                    {singleValue.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="input-group m-b">
              <span className="input-group-addon">Product</span>
              <select
                defaultValue={this.state.selectedValue}
                className="form-control"
                id="2"
                ref={(ref) => (this.productRef = ref)}
              >
                <option>Select</option>
                {this.state.productList.map((singleValue) => (
                  <option
                    key={singleValue.id}
                    name={singleValue}
                    value={singleValue.value}
                    data-obj={JSON.stringify(singleValue)}
                  >
                    {singleValue.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="input-group m-b">
              <span className="input-group-addon">Batch</span>
              <select
                className="form-control"
                id="3"
                ref={(ref) => (this.batchRef = ref)}
              >
                <option value="">Select</option>
                {this.state.batchList.map((singleValue) => (
                  <option
                    key={singleValue.id}
                    value={singleValue.batch}
                  // selected={singleValue.batch}
                  >
                    {singleValue.batch}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
        {/* <h4>
          <strong>
            {this.getRequestParams("type") != "Sales_Return"
              ? "Remaining Stock: " + this.state.totalstock
              : ""}
          </strong>
        </h4> */}

        {this.formData()}
        <button
          className="btn btn-info"
          onClick={() => this.checkValidationForProductionAddition()}
          style={{ float: "right" }}
          id="btn2"
        >
          Add
        </button>
        {this.selectedProductsView()}
        <div className="clearfix"></div>
        <div className="form-group">
          <div className="col-sm-8">
            <label className="control-label">Description</label>
            <textarea
              className="form-control"
              name="doc_description"
              value={this.document_description}
              rows="8"
              onChange={this.updateDescription}
            ></textarea>
          </div>
          <div className="col-sm-4">
            <div className="input-group m-b">
              <span className="input-group-addon">Total Amount</span>
              <input
                value={this.state.finalTotal}
                className="form-control"
                readOnly
              />
            </div>

            <div className="input-group m-b">
              <span className="input-group-addon">Discount</span>
              <input
                className="form-control"
                name="discountNumber"
                type="number"
                pattern="[0-9]*"
                onChange={this.handleChange.bind(this)}
                min="0"
              />
              <span className="input-group-addon">
                <input
                  name="discount"
                  type="checkbox"
                  onChange={this.handleChange.bind(this)}
                />
              </span>
            </div>

            <div className="input-group m-b">
              <span className="input-group-addon">Taxable Amount</span>
              <input
                value={this.state.taxableAmount}
                className="form-control"
                readOnly
              />
            </div>

            <div className="input-group m-b">
              <span className="input-group-addon">VAT</span>
              <input
                className="form-control"
                type="number"
                value="13"
                readOnly
              />
              <span className="input-group-addon">
                <input
                  name="vat"
                  type="checkbox"
                  onChange={this.handleChange.bind(this)}
                />
              </span>
            </div>

            <div className="input-group m-b">
              <span className="input-group-addon">Net Amount</span>
              <input
                value={this.state.netAmount}
                className="form-control"
                readOnly
              />
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="Cash"
                  checked={this.state.payType === "Cash"}
                  onChange={this.onValueChange}
                />
            Cash
          </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="Credit"
                  checked={this.state.payType === "Credit"}
                  onChange={this.onValueChange}
                />
            Credit
          </label>
            </div>

            {this.checkIfCashOrCredit()}
          </div>
        </div>

        <div className="clearfix"></div>
        <button className="btn btn-primary" onClick={this.submitForm} id="btn1">
          Submit
        </button>
      </React.Fragment>
    );
  }
}

export default TransactionReturn;
