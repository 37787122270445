import Axios from "axios";
import React, { useEffect, useState } from "react";
import { domain } from "../Url";

import "./OfferGroup.scss";
import { ProductField } from "./ProductField";

const type = ["product", "brand"];

const OfferGroup = () => {
  const [offer, setOffer] = useState();
  const [product, setProduct] = useState();
  const [offerId, setOfferId] = useState("");
  const [offerType, setOfferType] = useState("product");
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    Axios.get(`${domain}/offers.json`).then((res) => {
      setOffer(res.data);
    });
  }, []);

  useEffect(() => {
    Axios.get(`${domain}/api/ecommerce_products`).then((res) => {
      setProduct(res.data);
    });
  }, []);

  const createProduct = () => {
    return { id: [], discount: "", priority: "", id: Date.now() };
  };

  ///////////
  const [fields, setFields] = useState([createProduct()]);
  const [addFieldValue, setAddFieldValue] = useState([]);

  const pingFieldValue = (index, value) => {
    let temp = [...addFieldValue];
    temp.splice(index, 1, value);
    setAddFieldValue(temp);
  };

  const handleSubmit = () => {
    var postData = {
      offerId: offerId,
      offer: addFieldValue,
      offerType: offerType,
    };

    Axios.post("/api/save_offer_products", postData)
      .then((res) => {
        toastr.success(res.data?.message);
        setFields([createProduct()]);
        setAddFieldValue([]);
        setIsSubmited(!isSubmited);
      })
      .catch((err) => {
        let errorMessage = "submit failed";
        if (err.message) {
          errorMessage = err.message;
        }
        if (err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        alert(errorMessage.toString());
      });
  };

  return (
    <div className="offer-group">
      <div className="group-section">
        <div>
          <p className="content">Select Offers:</p>

          <select
            className="form-control"
            onChange={(e) => {
              setOfferId(e.target.value);
            }}
          >
            <option value="">Select offer value</option>
            {offer?.map((item, index) => {
              return (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="switch-btn-section">
          {type?.map((item) => {
            return (
              <button
                className={`switch-btn ${offerType === item && "active"}`}
                key={item}
                onClick={() => {
                  setOfferType(item);
                  setFields([createProduct()]);
                  setAddFieldValue([]);
                }}
              >
                {item}
              </button>
            );
          })}
        </div>

        <div className="product-group group-content">
          {fields?.map((item, index) => {
            return (
              <div className="product-group-block" key={item.id}>
                <div
                  className="remove"
                  onClick={() => {
                    let fieldTemp = [...fields];
                    fieldTemp.splice(index, 1);
                    setFields(fieldTemp);

                    let addFieldValueTemp = [...addFieldValue];
                    addFieldValueTemp.splice(index, 1);
                    setAddFieldValue(addFieldValueTemp);
                  }}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <ProductField
                  pingFieldValue={pingFieldValue}
                  offerType={offerType}
                  isSubmited={isSubmited}
                  index={index}
                />
              </div>
            );
          })}
          <button
            className="btn btn-primary prodcut-field-btn"
            onClick={() => {
              setFields([...fields, createProduct()]);
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div className="clearfix"></div>

      <button className="btn btn-primary" onClick={handleSubmit}>
        Save
      </button>
    </div>
  );
};

export default OfferGroup;
