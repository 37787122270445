import Axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function MultiSelect({ options, Optionvalue, optionSelect }) {
  return (
    <div className="app">
      <label>Products</label>
      <div className="dropdown-container">
        <Select
          options={options}
          placeholder="Select Products"
          value={Optionvalue}
          onChange={optionSelect}
          isSearchable={true}
          isMulti
        />
      </div>
    </div>
  );
}
